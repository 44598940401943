import React from "react";

const LibrarySong = ({
  song,
  setCurrentSong,
  audioRef,
  isPlaying,
  currentSong,
}) => {
  const songSelecthandler = async () => {
    await setCurrentSong(song);
    if (isPlaying) {
      audioRef.current.play();
    }
  };

  return (
    <divks
      onClick={songSelecthandler}
      className={`library-song ${
        song.id === currentSong.id ? "selected" : ""
      }  `}
    >
      <img alt={song.name} src={song.cover} />
      <div className='song-description'>
        <h3>{song.name}</h3>
        <h4>{song.artist}</h4>
      </div>
    </divks>
  );
};

export default LibrarySong;
